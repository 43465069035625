import { BFFClientType, BffClientTypeMap } from '@/application/BffClient'

export interface EnvironmentConfig {
  userpoolId: string
  appClientId: string
  clientAddress: string
  clientType: BFFClientType
  environment: string
  mixpanelToken?: string // intentionally optional as no desire for tracking on localhost
}

export const getEnvironmentConfig = (): EnvironmentConfig => {
  const userpoolId = process.env.NEXT_PUBLIC_USERPOOL_ID
  const appClientId = process.env.NEXT_PUBLIC_APP_CLIENT_ID
  const clientAddress = process.env.NEXT_PUBLIC_CLIENT_ADDRESS
  const clientTypeEnv = process.env.NEXT_PUBLIC_CLIENT_TYPE
  const environment = process.env.NEXT_PUBLIC_ENVIRONMENT
  const mixpanelToken = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN

  if (userpoolId === undefined) {
    throw new Error('userpoolId not defined. ENV var NEXT_PUBLIC_USERPOOL_ID')
  }

  if (appClientId === undefined) {
    throw new Error('appClientId not defined. ENV var NEXT_PUBLIC_APP_CLIENT_ID')
  }

  if (clientAddress === undefined) {
    throw new Error('clientAddress not defined. ENV var NEXT_PUBLIC_CLIENT_ADDRESS')
  }

  if (clientTypeEnv === undefined) {
    throw new Error('clientType not defined. ENV var NEXT_PUBLIC_CLIENT_TYPE')
  }

  const clientType = BffClientTypeMap.get(clientTypeEnv)

  if (clientType === undefined) {
    throw new Error('unknown client type: should be mock or grpc')
  }

  if (environment === undefined) {
    throw new Error('environment not defined. ENV var NEXT_PUBLIC_ENVIRONMENT')
  }

  return { userpoolId, appClientId, clientAddress, clientType, environment, mixpanelToken }
}
