import React, { useState } from 'react'
import { primitives } from '@aws-amplify/ui-react'
import { Body2 } from '@tumelo/designsystem'
import { AuthLayout } from './AuthLayout'

const { Fieldset, TextField, Button } = primitives

interface Props {
  onClickConfirm: (code: string) => Promise<void>
  onClickGoBack: () => void
}

export const ConfirmCode: React.FC<Props> = ({ onClickConfirm, onClickGoBack }) => {
  const [confirmCode, setConfirmCode] = useState<string>('')
  const [validationError, setValidationError] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const validate = () => {
    // regex for 6 digits
    const regex = /^\d{6}$/
    if (!regex.test(confirmCode)) {
      setValidationError('Confirmation code must be 6 digits')
      return false
    }
    setValidationError(undefined)
    return true
  }

  const localClickConfirm = async (code: string) => {
    if (!validate()) {
      return
    }
    setIsLoading(true)
    await onClickConfirm(code)
    setIsLoading(false)
  }

  // reconstruct authenticator component to get similar styles to the built in component
  return (
    <AuthLayout>
      <Fieldset legend="" className="amplify-fieldset">
        <Body2 semibold>We Emailed You</Body2>
        <Body2>
          Your code is on the way. To log in, enter the code we have emailed you. It may take a minute to arrive.
        </Body2>
        <TextField
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          label="Confirmation Code"
          onChange={e => setConfirmCode(e.target.value)}
          value={confirmCode}
          placeholder="Enter your code"
          errorMessage={validationError}
          hasError={validationError !== undefined}
        />
      </Fieldset>
      <Button variation="primary" onClick={() => localClickConfirm(confirmCode)} type="submit" isLoading={isLoading}>
        Submit
      </Button>
      <Button variation="link" onClick={() => onClickGoBack()}>
        Go Back
      </Button>
    </AuthLayout>
  )
}
