import { confirmSignIn } from 'aws-amplify/auth'
import { useAuthenticator } from '@aws-amplify/ui-react'
import React, { PropsWithChildren, useState } from 'react'
import '@aws-amplify/ui-react/styles.css'
import { ConfirmCodeError } from '@/components/Auth/ConfirmCodeError'
import { ConfirmCode } from '@/components/Auth/ConfirmCode'
import { AmplifyAuthenticator } from '@/components/Auth/AmplifyAuthenticator'

export const AuthContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const { authStatus } = useAuthenticator(context => [context.authStatus]) // only change this hook when authStatus changes. Avoids unecessary re-renders.

  const [state, setState] = useState<'showAuthenticator' | 'confirmCode' | 'mfaAuthError'>('showAuthenticator')
  const [mfaAuthError, setMFAAuthError] = useState<string | undefined>(undefined)

  const onClickConfirm = async (code: string) => {
    setMFAAuthError(undefined) // clear any previous errors
    try {
      await confirmSignIn({
        challengeResponse: code,
      })
      setState('showAuthenticator') // ensures we show the authenticator if we become unauthenticated
    } catch (e: unknown) {
      setState('mfaAuthError')
      if (e instanceof Error) {
        setMFAAuthError(e.message)
      } else {
        setMFAAuthError('Something went wrong')
      }
    }
  }

  const onClickGoBack = () => {
    setState('showAuthenticator')
  }

  if (authStatus === 'authenticated') {
    return <>{children}</>
  }
  if (state === 'mfaAuthError') {
    return <ConfirmCodeError error={mfaAuthError} onClickGoBack={onClickGoBack} />
  }
  if (state === 'confirmCode') {
    return <ConfirmCode onClickConfirm={onClickConfirm} onClickGoBack={onClickGoBack} />
  }

  return <AmplifyAuthenticator showConfirmMfaCode={() => setState('confirmCode')} />
}
