import {
  SampleAccountsSummaryMap,
  SampleCompleteBrokerConnectionResponse,
  SampleFetchAvailableVotingPoliciesResponse,
  SampleGetInvestorVotingPolicyResponse,
  SampleListBrokerConnectionsResponse,
  SampleListBrokersResponse,
  SampleStartBrokerConnectionResponse,
  SampleListHistoricalVotesResponse,
  SampleRefreshBrokerConnectionResponse,
} from '@/application/Mocks/service'
import {
  CompleteBrokerConnectionRequest,
  CompleteBrokerConnectionResponse,
  FetchAvailableVotingPoliciesResponse,
  GetSelectedVotingPolicyResponse,
  ListBrokerConnectionsResponse,
  ListBrokersResponse,
  RetailBffService,
  StartBrokerConnectionRequest,
  StartBrokerConnectionResponse,
  FetchBrokerConnectionSummaryRequest,
  UpsertVotingPolicySelectionRequest,
  UpsertVotingPolicySelectionResponse,
  DeleteBrokerConnectionRequest,
  ListHistoricalVotesResponse,
  RefreshBrokerConnectionRequest,
  RefreshBrokerConnectionResponse,
  GetUserResponse,
} from '@/application/proto/tumelo/retailbff/v1/service'
import { AccountsSummary } from '@/application/proto/tumelo/retailbff/v1/account'
import { SampleAccountsSummary } from '@/application/Mocks/account'
import { Empty } from '@/application/proto/google/protobuf/empty'

export const MockClient: RetailBffService = {
  ListBrokers: async (): Promise<ListBrokersResponse> => {
    return SampleListBrokersResponse()
  },
  StartBrokerConnection: async ({
    brokerName,
  }: StartBrokerConnectionRequest): Promise<StartBrokerConnectionResponse> => {
    await sleep(2000)
    return SampleStartBrokerConnectionResponse({
      url: `/broker-selection/complete-connection/mock-broker?code=${brokerName}`,
    })
  },
  CompleteBrokerConnection: async ({
    authorizationCode,
  }: CompleteBrokerConnectionRequest): Promise<CompleteBrokerConnectionResponse> => {
    await sleep(2000)
    return SampleCompleteBrokerConnectionResponse({ brokerConnectionName: authorizationCode })
  },
  ListBrokerConnections: async (): Promise<ListBrokerConnectionsResponse> => {
    return SampleListBrokerConnectionsResponse()
  },
  GetSelectedVotingPolicy: async (): Promise<GetSelectedVotingPolicyResponse> => {
    await sleep()
    return SampleGetInvestorVotingPolicyResponse()
  },
  FetchBrokerConnectionSummary: async ({
    brokerConnectionName,
  }: FetchBrokerConnectionSummaryRequest): Promise<AccountsSummary> => {
    return SampleAccountsSummaryMap.get(brokerConnectionName) ?? SampleAccountsSummary()
  },
  FetchAvailableVotingPolicies: async (): Promise<FetchAvailableVotingPoliciesResponse> => {
    return SampleFetchAvailableVotingPoliciesResponse()
  },
  UpsertVotingPolicySelection: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { votingPolicyName }: UpsertVotingPolicySelectionRequest
  ): Promise<UpsertVotingPolicySelectionResponse> => {
    await sleep(2000)
    return {}
  },
  DeleteBrokerConnection: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { brokerConnectionName }: DeleteBrokerConnectionRequest
  ) => {
    await sleep(2000)

    return Empty
  },
  ListHistoricalVotes: async (): Promise<ListHistoricalVotesResponse> => {
    return SampleListHistoricalVotesResponse()
  },
  RefreshBrokerConnection: async ({
    brokerConnectionName,
  }: RefreshBrokerConnectionRequest): Promise<RefreshBrokerConnectionResponse> => {
    await sleep(2000)
    return SampleRefreshBrokerConnectionResponse({
      refreshUrl: `/broker-selection/refresh-connection/mock-broker?code=${brokerConnectionName}`,
    })
  },
  GetUser: async (): Promise<GetUserResponse> => {
    return { userId: 'mock-user-id', investorName: 'mock-investor-id' }
  },
}

export const sleep = (ms = 500) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}
